import {Component} from "react";

class ToddlerGroupPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <h2>Toddler Group</h2>
                <p>
                    This group meets in the Gabriel Hall on Tuesdays in Term Time from 9.45am – 11.30am. There are a
                    range of play activities, refreshments, and fellowship. It is for all those who are below statutory
                    school age with their parents in attendance.
                </p>
                <p>
                    For more information, please contact Sally Pearson on <a
                    href="tel:&#x30;&#x37;&#x39;&#x34;&#x31;&#x32;&#x33;&#x32;&#x37;&#x33;&#x35;">&#x30;&#x37;&#x39;&#x34;&#x31;&#x20;&#x32;&#x33;&#x32;&#x20;&#x37;&#x33;&#x35;</a>
                </p>
            </div>
        );
    }
}

export default ToddlerGroupPage;
